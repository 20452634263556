import React, { useState, useRef } from "react"
import { AnimatePresence, motion } from "framer-motion"
import Slider from "react-slick"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageHeader from "../../components/pageheader"
import PageBottom from "../../components/pagebottom"
import * as styles from "../../styles/about.module.css"
import { graphql } from "gatsby"

const AboutUsPage = ({ data }) => {
    const [isWithVideo, setIsWithVideo] = useState(false)
    const [isVideoVisible, setIsVideoVisible] = useState(true)
    const slideRef = useRef()
    const goToPrev = () => {
        if (slideRef) slideRef.current.slickPrev()
    }
    const goToNext = () => {
        if (slideRef) slideRef.current.slickNext()
    }
    return (
        <Layout activeMenuId={2}>
            <Seo
                title="About Us"
                description={data?.wpPage?.seo?.metaDesc}
                date={data?.wpPage?.date}
                img={data?.wpPage?.seo?.opengraphImage?.mediaItemUrl}
                imgHeight={
                    data?.wpPage?.seo?.opengraphImage?.mediaDetails?.height
                }
                imgWidth={
                    data?.wpPage?.seo?.opengraphImage?.mediaDetails?.width
                }
            />
            {data?.wpPage?.blocks.map((item, i) => {
                switch (item?.name) {
                    case "acf/cover-main":
                        const coverMain = item?.coverMain
                        return (
                            <PageHeader
                                key={i}
                                hasUnderline
                                title={coverMain?.title}
                                subTitle={coverMain?.text}
                                image={coverMain?.img?.mediaItemUrl}
                                breadcrumbs={[
                                    {
                                        label: `${coverMain?.title}`,
                                        link: null,
                                    },
                                ]}
                            />
                        )
                    case "acf/col-text-w-image":
                        const colTextWImage = item?.colTextWImage
                        return (
                            <div key={i} className="container mx-auto">
                                <div className={styles.content}>
                                    <div className={styles.slideWrap}>
                                        <Slider
                                            ref={slideRef}
                                            autoplay
                                            autoplaySpeed={5000}
                                            arrows={false}
                                            dots={false}
                                        >
                                            {colTextWImage?.images.map(
                                                (img, imgI) => (
                                                    <div key={imgI}>
                                                        <img
                                                            src={
                                                                img?.img
                                                                    ?.mediaItemUrl
                                                            }
                                                            alt={
                                                                img?.img
                                                                    ?.altText
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </Slider>
                                        {colTextWImage?.images.length > 1 && (
                                            <>
                                                <button
                                                    className={`${styles.arrowLeft} ${styles.aboutArrow}`}
                                                    onClick={goToPrev}
                                                >
                                                    Previous
                                                </button>
                                                <button
                                                    className={`${styles.arrowRight} ${styles.aboutArrow}`}
                                                    onClick={goToNext}
                                                >
                                                    Next
                                                </button>
                                            </>
                                        )}
                                    </div>
                                    <div className={styles.text}>
                                        <p>{colTextWImage?.description}</p>
                                        <h2>{colTextWImage?.title}</h2>
                                        <p>{colTextWImage?.text}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    case "core/group":
                        return (
                            <div key={i} className="container mx-auto">
                                {item?.innerBlocks.map((block, blockI) => {
                                    switch (block?.name) {
                                        case "core/paragraph":
                                            return (
                                                <div
                                                    key={blockI}
                                                    className={`text-center px-4 lg:px-0 my-8 lg:mt-16 lg:mb-20 mx-auto ${styles.videoText}`}
                                                >
                                                    <p>
                                                        {
                                                            block?.attributes
                                                                ?.content
                                                        }
                                                    </p>
                                                    <button
                                                        className={`mt-16 ${styles.watchVideo}`}
                                                        onClick={() =>
                                                            setIsWithVideo(
                                                                !isWithVideo
                                                            )
                                                        }
                                                    >
                                                        Watch our video
                                                    </button>
                                                </div>
                                            )
                                        case "core/embed":
                                            return (
                                                <AnimatePresence key={blockI}>
                                                    {isWithVideo && (
                                                        <motion.div
                                                            className={
                                                                styles.videoWrapper
                                                            }
                                                            initial={{
                                                                opacity: 0,
                                                            }}
                                                            animate={{
                                                                opacity: 1,
                                                            }}
                                                            exit={{
                                                                opacity: 0,
                                                            }}
                                                        >
                                                            <div
                                                                className={
                                                                    styles.videoBox
                                                                }
                                                                style={{
                                                                    paddingTop:
                                                                        "56.25%",
                                                                }}
                                                            >
                                                                {isVideoVisible ? (
                                                                    <iframe
                                                                        className="h-full w-full inset-0 absolute"
                                                                        src={
                                                                            block
                                                                                ?.attributes
                                                                                ?.url
                                                                        }
                                                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                                        title="Kota Wisata"
                                                                        frameBorder="0"
                                                                        webkitallowfullscreen="true"
                                                                        mozallowfullscreen="true"
                                                                        allowFullScreen
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        <div
                                                                            className={
                                                                                styles.videoCover
                                                                            }
                                                                        />
                                                                        <button
                                                                            onClick={() =>
                                                                                setIsVideoVisible(
                                                                                    true
                                                                                )
                                                                            }
                                                                            className={
                                                                                styles.videoPlay
                                                                            }
                                                                        >
                                                                            Play
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            )
                                    }
                                })}
                            </div>
                        )
                    case "acf/experiment":
                        const mapWStats = item?.mapWStats
                        const mapIframe = data?.wpPage?.blocks.find(v => v?.name == "core/html")?.originalContent
                        return <PageBottom key={i} mapWStats={mapWStats} mapScript={mapIframe} />
                }
            })}
        </Layout>
    )
}

export const query = graphql`
    {
        wpPage(slug: { eq: "about" }) {
            date
            seo {
                title
                metaDesc
                opengraphImage {
                    id
                    mediaItemUrl
                    mediaDetails {
                        height
                        width
                    }
                }
            }
            blocks {
                ... on WpAcfCoverMainBlock {
                    coverMain {
                        img {
                            altText
                            mediaItemUrl
                        }
                        title
                        text
                    }
                }
                name
                ... on WpAcfColTextWImageBlock {
                    colTextWImage {
                        description
                        title
                        text
                        images {
                            img {
                                altText
                                mediaItemUrl
                            }
                        }
                    }
                }
                ... on WpCoreGroupBlock {
                    innerBlocks {
                        name
                        ... on WpCoreParagraphBlock {
                            attributes {
                                ... on WpCoreParagraphBlockAttributes {
                                    content
                                }
                            }
                        }
                        ... on WpCoreEmbedBlock {
                            attributes {
                                ... on WpCoreEmbedBlockAttributes {
                                    url
                                }
                            }
                        }
                    }
                }
                ... on WpCoreHtmlBlock {
                    originalContent
                }
                ... on WpAcfExperimentBlock {
                    mapWStats {
                        stats {
                            label
                            value
                        }
                    }
                }
            }
        }
    }
`

export default AboutUsPage
