// extracted by mini-css-extract-plugin
export var content = "about-module--content--G-1eK";
export var slideWrap = "about-module--slideWrap--2OVXi";
export var aboutArrow = "about-module--aboutArrow--3CAVg";
export var arrowLeft = "about-module--arrowLeft--2NdAB";
export var arrowRight = "about-module--arrowRight--Co8ha";
export var text = "about-module--text--2QAlm";
export var videoText = "about-module--videoText--14iTQ";
export var watchVideo = "about-module--watchVideo--u96E_";
export var videoWrapper = "about-module--videoWrapper--ftjUQ";
export var videoBox = "about-module--videoBox--2q_Wn";
export var videoCover = "about-module--videoCover--16LkV";
export var videoPlay = "about-module--videoPlay--3Fc59";